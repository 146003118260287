import React from 'react';

type InputProps = {
    name?: string;
    id: string;
    type: string;
    onChange: any;
    value?: string;
    className?: string;
    placeholder?: string;
    checked?: string;
  }

const InputField = ({
  id,
  type,
  name,
  onChange,
  value,
  className,
  placeholder,
}: InputProps) => (
  <input
    id={id}
    type={type}
    name={name}
    onChange={onChange}
    value={value}
    className={className}
    placeholder={placeholder}
  />
);

export default InputField;
