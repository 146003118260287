import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className='container'>
    <h1>This page was not found</h1>
    <h5>
      Click 
      {' '}
      <Link to='/'>here</Link>
      {' '}
      to return home
    </h5>
  </div>
);

export default NotFound;
