import React from 'react';
import InputField from './InputField';

const InputGroup = ({
  onChange, value, onSubmit, onSelect, selectedFilter, exportSearch, filters, type, link, history,
}: any): JSX.Element => {
  const filterItem = (filtersArray: Array<string>, selection: any) => {
    const mapped = filtersArray.map(
      (filter) => <button className='dropdown-item' type='button' onClick={selection} key={filter} name={filter}>{filter}</button>,
    );
    return mapped;
  };

  return (
    <form>
      
      <div className='input-group mb-3'>
        {
            filters.length ? (
              <div className='input-group-prepend'>
                <button
                  className='btn btn-outline-secondary dropdown-toggle'
                  type='button'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  {selectedFilter}
                </button>
                <div className='dropdown-menu'>
                  <h6 className='dropdown-header'>Search Filters</h6>
                  {filterItem(filters, onSelect)}
                </div>
              </div>
            ) : null
          }
        <InputField
          type='text'
          className='form-control'
          aria-label='Text input with dropdown button'
          value={value}
          onChange={onChange}
          id='searchMain'
          name='searchMain'
          placeholder='Search'
        />
        <button className='btn btn-primary mr-1' type='submit' onClick={(e) => onSubmit(e)}>Search</button>
        <button className='btn btn-secondary mr-1' type='submit' onClick={(e) => exportSearch(e)}>Export Search</button>
        <button className='btn btn-primary' type='submit' onClick={() => history.push(`/add-${link}`)}>
          Add 
          {' '}
          {' '}
          {type}
        </button>
      </div>
    </form>
  );
};

export default InputGroup;
