import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import logger from 'redux-logger';
import reducer from './reducers';

let middleware, store;

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, reducer);

/* istanbul ignore if */
if (process.env.NODE_ENV === 'development') {
  middleware = applyMiddleware(logger);
  store = createStore(persistedReducer, middleware);
} else {
  store = createStore(persistedReducer);
}
const persistor = persistStore(store);

export default { store, persistor };
