/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import { renderMessage } from '../SearchScreen';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    style?: CSSProperties;
  }
}

const OrganizationAPI = '/api/v1/schools/';

const AddOrganization = () => {
  const id = window.location.pathname.substring(19);
  const history = useHistory();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [siteState, setSiteState] = useState('');
  const [zipcode, setZipCode] = useState('');
  const [zipExt, setZipExt] = useState('');
  const [telephone, setTelephone] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const getConfig = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    return config;
  };

  const getSiteInfo = async () => {
    let res;
    try {
      const config = await getConfig();
      res = await axios.get(`${OrganizationAPI}${id}`, config);
    } catch (e) {
      return e;
    }
    const { data } = res;
    setName(data.name);
    setAddress(data.address);
    setCity(data.city);
    setSiteState(data.state);
    setZipCode(data.zipcode);
    if (data.zip_extension !== null) setZipExt(data.zip_extension);
    if (data.telephone !== null) setTelephone(data.telephone);
    return true;
  };
  useEffect(() => {
    if (id.length) getSiteInfo();
  }, []);

  const createOrganization = async () => {
    let res,
      state = siteState;
    if (siteState === 'or' || siteState === 'OR') state = 'Oregon'; 
    const data: any = {
      name,
      address,
      city,
      state,
      zipcode,
    };
    if (zipExt !== null && zipExt.length) data.zip_extension = zipExt;
    if (telephone.length) data.telephone = telephone;

    try {
      const config = await getConfig();
      if (id.length) {
        res = await axios.patch(`${OrganizationAPI}${id}`, data, config);
      } else {
        res = await axios.post(`${OrganizationAPI}`, data, config);
      }
    } catch (e) {
      return e;
    }
    return res;
  };

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void | boolean> => {
    e.preventDefault();
    let res;

    if (id.length) {
      try {
        res = await createOrganization();
      } catch (err) {
        console.log('error message', err);// eslint-disable-line
      }
    } else {
      try {
        res = await createOrganization();
      } catch (err) {
        console.log('error message', err);// eslint-disable-line
      }
    }
    
    if (res.status === 201 || res.status === 200) {
      sessionStorage.setItem('message', `Organization ${res.status === 200 ? 'updated' : 'created'} successfully`);
      sessionStorage.setItem('messageType', 'Success');
      return history.push('/organizations');
    }
    const { message, detail } = res.response.data;
    if (message !== undefined) {
      setErrorMessage(`Organization was not created due to error: ${message}`);
    } else if (detail !== undefined) {
      setErrorMessage(`Organization was not created due to error: ${detail}`);
    } else {
      setErrorMessage('Organization was not created due an error');
    }
    return false;
  };

  const validateData = () => {
    if (!name.length) return false;
    if (!address.length) return false;
    if (!siteState.length) return false;
    if (!city.length) return false;
    if (!zipcode.length) return false;
    return true;
  };

  return (
    <div className='full-height container'>
      <div className='row'>
        <div className='col'>
          <h1>
            {id ? 'Edit' : 'Add'}
            {' '}
            Organization
          </h1>
        </div>
        <div className='col d-flex justify-content-end'>
          <button className='btn btn-primary' type='button' onClick={() => history.push('/organizations')}>Back to Sites Search</button>
        </div>
      </div>
      {renderMessage()}
      <form>
        <div className='form-group'>
          <span>Site Name: </span>
          <input
            type='text'
            className='form-control'
            placeholder='Name...'
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <span>Street Address: </span>
          <input
            type='text'
            className='form-control'
            placeholder='Address...'
            onChange={(e) => setAddress(e.target.value)}
            value={address}
          />
          <span>City: </span>
          <input
            type='text'
            className='form-control'
            placeholder='City...'
            onChange={(e) => setCity(e.target.value)}
            value={city}
          />
          <span>State: </span>
          <input
            type='text'
            className='form-control'
            placeholder='State...'
            onChange={(e) => setSiteState(e.target.value)}
            value={siteState}
          />
          <span>Zip Code: </span>
          <input
            type='text'
            className='form-control'
            placeholder='Zip Code...'
            onChange={(e) => setZipCode(e.target.value)}
            value={zipcode}
          />
          <span>Zip Extension: </span>
          <input
            type='text'
            className='form-control'
            placeholder='Zip Extension...'
            onChange={(e) => setZipExt(e.target.value)}
            value={zipExt}
          />
          <span>Telephone: </span>
          <input
            type='text'
            className='form-control'
            placeholder='Telephone...'
            onChange={(e) => setTelephone(e.target.value)}
            value={telephone}
          />
        </div>
        <button className='btn btn-primary' type='submit' disabled={!validateData()} onClick={(e) => onSubmit(e)}>Save</button>
      </form>
    </div>
  );
};

export default AddOrganization;
