import React from 'react';
import { Link } from 'react-router-dom';
import { deauth } from '../lib/auth';

const NavBar = () => {
  const url = window.location.href.split('/')[3];
  return (
    <nav className='navbar navbar-expand-lg navbar-dark bg-dark'>
      <p className='navbar-brand m-0'>VEEWs Activation Server</p>
      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarNavDropdown'
        aria-controls='navbarNavDropdown'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon' />
      </button>
      <div className='collapse navbar-collapse' id='navbarNavDropdown'>
        <ul className='navbar-nav ml-auto mr-3'>
          <li className={url === 'organizations' ? 'nav-item active' : 'nav-item'}>
            <Link className='nav-link' to='/organizations'>Sites</Link>
          </li>
          <li className={url === 'orders' ? 'nav-item active' : 'nav-item'}>
            <Link className='nav-link' to='/orders'>Orders</Link>
          </li>
          <li className={url === 'agreements' ? 'nav-item active' : 'nav-item'}>
            <Link className='nav-link' to='/agreements'>Agreements</Link>
          </li>
        </ul>
        <button className='btn btn-danger' type='button' onClick={deauth}>Logout</button>
      </div>
    </nav>
  );
};

export default NavBar;
