import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-dates/initialize';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Stylesheets/main.sass';
import App from './App';
import store from './Redux/store';

export default ReactDOM.render(
  <Provider store={store.store}>
    <PersistGate persistor={store.persistor}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root') || document.createElement('div'),
);
