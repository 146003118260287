const initState = {
  msg: '',
  msg_type: 'success',
};

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'ADD_ORDER':
      return { ...state, msg: action.payload.msg, msg_type: action.payload.msg_type };
    case 'RESET_ORDER_MSG':
      return { msg: '', msg_type: 'success' };
    default:
      return state;
  }
};

export default reducer;
