import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Button from './Button';
import DeleteModal from './DeleteModal';
 
type OrderProps = {
  id: number;
  customer_name: string;
  expiration: string;
};

export const onDeleteOrder = async (e:any, id: string, refetching: Function) => {
  e.persist();
  const orderApi = '/api/v1/orders/detail/';
  let res;
  const getConfig = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    return config;
  };
    
  try {
    const config = await getConfig();
    res = await axios.delete(`${orderApi}${id}`, config);
    sessionStorage.setItem('message', 'Organization deleted successfully');
    sessionStorage.setItem('messageType', 'Success');
  } catch (error) {
    sessionStorage.setItem('message', 'Organization was not deleted');
    sessionStorage.setItem('messageType', 'Fail');
    return false;
  }
  sessionStorage.setItem('message', 'Organization deleted successfully');
  sessionStorage.setItem('messageType', 'Success');
  refetching(e);
  return true;
};

export const organizationData = (orders: any, getNewOrders: Function, pageModifier: number) => {
  if (orders === null || orders === undefined) return null;
  const mapped = orders.map((order: OrderProps, index: number) => {
    const target = `#deleteModal${order.id}`;
    return (
      <tr key={order.id}>
        <th scope='row'>{index + pageModifier}</th>
        <td>{order.customer_name}</td>
        <td>{order.expiration ? moment(order.expiration).format('MMMM DD, YYYY') : 'N/A' }</td>
        <td>
          <div className='row'>
            <div className='col-1 d-flex'>
              <Button
                name={<FontAwesomeIcon icon={faEdit} />}
                className='border-0 bg-white'
                onClick={() => window.location.assign(`/edit-order/${order.id}`)}
              />
            </div>
            <div className='col-1 d-flex'>
              {/* Button trigger modal */}
              <Button name={<FontAwesomeIcon icon={faTrashAlt} />} className='border-0 bg-white' dataToggle='modal' dataTarget={target} />
              {/* Modal */}
              <DeleteModal item={{ id: order.id, name: order.customer_name }} refetch={getNewOrders} onDelete={onDeleteOrder} />
            </div>
          </div>
        </td>
      </tr>
    ); 
  });

  if (orders.length === 0) return <h6>No orders match that search</h6>;
  return (
    <div className='table-responsive'>
      <table className='table'>
        <thead className='thead-dark'>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Customer Name</th>
            <th scope='col'>Expiration Date</th>
            <th scope='col'>Modify</th>
          </tr>
        </thead>
        <tbody>
          {mapped}
        </tbody>
      </table>
    </div>
  );
};

const OrderTable = ({ orders, fetchMore, pageModifier }: any) => organizationData(orders, fetchMore, pageModifier);

export default OrderTable;
