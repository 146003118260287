import React from 'react';

type PaginateProps = {
    totalItems: number;
    next: any;
    previous: any;
    handlePagination: Function;
    currentPage: number;
  }

const Pagination = ({
  totalItems, next, previous, handlePagination, currentPage,
}: PaginateProps) => {
  const renderPrevious = () => {
    if (previous === null) {
      return (
        <li className='page-item disabled'>
          <span className='page-link'>&laquo;</span>
          <span className='sr-only'>Previous</span>
        </li>
      );
    } 
    return (
      <li className='page-item'>
        <button className='page-link' type='submit' onClick={() => handlePagination(previous)}>&laquo;</button>
        <span className='sr-only'>Previous</span>
      </li>
    );
  };

  const renderNext = () => {
    if (next === null) {
      return (
        <li className='page-item disabled'>
          <span className='page-link'>&raquo;</span>
          <span className='sr-only'>Next</span>
        </li>
      );
    } 
    return (
      <li className='page-item'>
        <button className='page-link' type='submit' onClick={() => handlePagination(next)}>&raquo;</button>
        <span className='sr-only'>Next</span>
      </li>
    );
  };

  return (
    <nav aria-label='...'>
      <div className='row m-3'>
        <div className='col-10'>
          <ul className='pagination justify-content-center'>
            {renderPrevious()}
            <li className='page-item active'>
              <span className='page-link'>
                {currentPage}
                <span className='sr-only'>(current)</span>
              </span>
            </li>
            {renderNext()}
          </ul>
        </div>
        <div className='col-2'>
          <div>
            Results:
            {totalItems}
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Pagination;
