import React from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import InputGroup from '../Components/InputGroup';
import OrgTable from '../Components/OrganizationTable';
import Pagination from '../Components/Pagination';

const OrganizationAPI = '/api/v1/schools/';

export const queryOrganizations = async (filter: string, query: string) => {
  let res;
  const getConfig = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    return config;
  };
  
  try {
    const config = await getConfig();
    const lowercaseFilter = filter.toLowerCase();
    const queryCall = `?${lowercaseFilter}__icontains=${query}`;
    if (query.length < 1) {
      res = await axios.get(`${OrganizationAPI}`, config);
    } else {
      res = await axios.get(`${OrganizationAPI}${queryCall}`, config);
    }
  } catch (e) {
    return e;
  }
  return res;
};

export const messageTimeout = () => {
  const timer = setTimeout(() => {
    sessionStorage.setItem('message', '');
  }, 500);
  return () => clearTimeout(timer);
};

export const renderMessage = () => {
  const messageExists = sessionStorage.getItem('message');
  const messageType = sessionStorage.getItem('messageType');
  messageTimeout();
  if (messageExists === '') return null;
  if (messageType === 'Success') {
    return (
      <div className='alert alert-success text-center' role='alert'>
        {messageExists}
      </div>
    );
  } if (messageType === 'Fail') {
    return ( 
      <div className='alert alert-danger text-center' role='alert'>
        {messageExists}
      </div>
    );
  }
  return true;
};

const SearchContainer = () => {
  const history = useHistory();
  const [value, setValue] = React.useState('');
  const [filter, setFilter] = React.useState('City');
  const [queriedOrganizations, setQueriedOrganizations] = React.useState([]);
  const [totalItems, setItems] = React.useState(0);
  const [next, setNext] = React.useState(null);
  const [previous, setPrevious] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);

  React.useEffect(() => {
    // Create an scoped async function in the hook
    async function fetchAllOrganizations() {
      const res = await queryOrganizations(filter, value);
      if (res.status === 200) {
        let nextVal, prevVal;
        setQueriedOrganizations(res.data.results);
        if (res.data.results.length > 0) {
          setItems(res.data.count);
          if (res.data.next !== null) nextVal = res.data.next.split(':8000')[1]; // eslint-disable-line
          if (res.data.previous !== null) prevVal = res.data.previous.split(':8000')[1]; // eslint-disable-line
          setNext(nextVal || res.data.next);
          setPrevious(prevVal || res.data.previous);
          return res.data.results;
        } 
        setValue('');
        return res.data.results;
      }
      setValue('');
      return res;
    }
    // Execute the created function directly
    fetchAllOrganizations();
    // eslint-disable-next-line
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.name);
  };

  const handlePagination = async (url: any) => {
    let res;
    const urlPage = url.split('=')[1];
    if (isNaN(urlPage)) {// eslint-disable-line
      setCurrentPage(1);
    } else {
      setCurrentPage(Number(urlPage));
    }
    const getConfig = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      };
      return config;
    };
      
    try {
      const config = await getConfig();
      res = await axios.get(url, config);
      if (res.status === 200) {
        let nextVal, prevVal;
        setQueriedOrganizations(res.data.results);
        if (res.data.results.length > 0) {
          setItems(res.data.count);
          if (res.data.next !== null) nextVal = res.data.next.split(':8000')[1]; // eslint-disable-line
          if (res.data.previous !== null) prevVal = res.data.previous.split(':8000')[1]; // eslint-disable-line
          setNext(nextVal || res.data.next);
          setPrevious(prevVal || res.data.previous);
          return res.data.results;
        } 
        setValue('');
        return res.data.results;
      }
    } catch (e) {
      return e;
    }
    return res;
  };

  const exportSearch = async (e: any) => {
    e.preventDefault();
    let res;
    const csvAPI = '/api/v1/csv/';
    
    try {
      const lowercaseFilter = filter.toLowerCase();
      const queryCall = `?${lowercaseFilter}__icontains=${value}`;
      if (value.length < 1) {
        res = await axios({
          url: `${csvAPI}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
          responseType: 'blob',
        });
      } else {
        res = await axios({
          url: `${csvAPI}${queryCall}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
          responseType: 'blob',
        });
      }
    } catch (error) {
      return error;
    }
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'export.csv');
    document.body.appendChild(link);
    link.click();
    return res;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const res = await queryOrganizations(filter, value);
    if (res.status === 200) {
      setQueriedOrganizations(res.data.results);
      if (res.data.results.length > 0) {
        setItems(res.data.count);
        setCurrentPage(1);
        setNext(res.data.next);
        setPrevious(res.data.previous);
        return res.data.results;
      } 
      setValue('');
      return res.data.results;
    }
    setValue('');
    return res;
  };

  const modifiyPage = () => {
    if (currentPage > 1) {
      return ((currentPage - 1) * 25 + 1);
    } 
    return currentPage;
  };

  const pageModifier = modifiyPage();
  return (
    <div>
      <div className='search-container'>
        <div className='row mb-3'>
          <div className='col-8'>
            <h3>Sites</h3>
          </div>
        </div>
        {renderMessage()}
        <InputGroup
          history={history}
          onChange={onChange}
          value={value}
          onSubmit={onSubmit}
          onSelect={onSelect}
          filters={['Name', 'Address', 'City', 'State', 'ZipCode']}
          selectedFilter={filter}
          exportSearch={exportSearch}
          type='Site'
          link='organization'
        />
        <OrgTable organizations={queriedOrganizations} callback={onSubmit} pageModifier={pageModifier} />
        <Pagination totalItems={totalItems} next={next} previous={previous} handlePagination={handlePagination} currentPage={currentPage} />
      </div>
    </div>
  );
};

export default SearchContainer;
