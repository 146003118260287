/* eslint-disable camelcase, class-methods-use-this */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';

// const certFile = require('./shakealert_vernemq_ca.crt');

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    style?: CSSProperties;
  }
}

const RegisterAPI = '/api/v1/schools/';
const OrderApi = '/api/v1/orders/';

const RegisterOrg = () => {
  const history = useHistory();
  const [ncesid, setNcesid] = useState('');
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [activationKey, setActivationKey] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [createOrder, setCreateOrder] = useState(false);

  const getConfig = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    return config;
  };

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const config = await getConfig();
    const activation = activationKey.replace(/\s+/g, '');
    const data = { order_number: activation };
    try {
      const orderData = await axios.get(`${OrderApi}?order_number=${activation}`, config);
      if (orderData.data.count === 0) {
        setCreateOrder(true);
        return false;
      }
    } catch (err) {
      setCreateOrder(true);
      return false;
    }
    try {
      const res = await axios.post(`${RegisterAPI}register/${ncesid}`, data, config);
      if (res.status === 200) {
        setMessageType('success');
        setStatusMessage('Site successfully activated');
        setActivationKey(res.data.order_number);
        setUsername(res.data.username);
        setPassword(res.data.password);
        return true;
      }
      setMessageType('err');
      setStatusMessage('Error activating the organization. Ensure it is located in CA, OR, WA and please try again.');
      return false;
    } catch (err) {
      setMessageType('err');
      setStatusMessage('Error registering the organization. Ensure it is located in CA, OR, WA and please try again.');
      return false;
    }
  };

  const checkRegistration = async (id:string) => {
    let res;
    try {
      const config = await getConfig();
      res = await axios.get(`${RegisterAPI}${id}`, config);
    } catch (e) {
      return e;
    }
    const organizationData = res.data;
    const newName = organizationData.name.toLowerCase()
      .split(' ')
      .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    setName(newName);
    setNcesid(organizationData.ncesid);
    setUsername(organizationData.username);
    setPassword(organizationData.password);
    setActivationKey(organizationData.order_number);
    return true;
  };

  useEffect(() => {
    const urlPath = window.location.pathname;
    const id = urlPath.substring(10);
    checkRegistration(id);
  }, []);

  const backToSearchBtn = () => (
    <div className='row mt-2'>
      <div className='col'>
        <h1>
          Register 
          {' '}
          {name}
        </h1>
      </div>
      <div className='col d-flex justify-content-end'>
        <button
          style={{ height: '50px' }}
          className='btn btn-primary'
          type='button'
          onClick={() => history.push('/organizations')}
        >
          Back to Search
        </button>
      </div>
    </div>
  );

  const renderMessage = () => {
    if (messageType === 'success') {
      return (
        <div className='alert alert-success text-center mt-2' role='alert'>
          {statusMessage}
        </div>
      );
    } if (messageType === 'err') {
      return ( 
        <div className='alert alert-danger text-center mt-2' role='alert'>
          {statusMessage}
        </div>
      );
    }
    return null;
  };

  if (activationKey.length && password.length && username.length) {
    return (
      <div className='full-height container'>
        <div className='row mt-2'>
          <div className='col'>
            <h1>
              {name}
              {' '}
              is already activated
            </h1>
          </div>
          <div className='col d-flex justify-content-end'>
            <button
              style={{ height: '50px' }}
              className='btn btn-primary'
              type='button'
              onClick={() => history.push('/organizations')}
            >
              Back to Search
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <h3 className='mt-4'>Activation Info:</h3>
            <h5>
              Username:
              {username}
            </h5>
            <h5>
              Activation Key:
              {activationKey}
            </h5>
          </div>
        </div>
      </div>
    );
  }
  if (createOrder) {
    return (
      <div>
        {backToSearchBtn()}
        <div className='alert alert-danger text-center mt-2' role='alert'>
          No order found. Please create new order before proceeding.
        </div>
      </div>
    ); 
  }
  return (
    <div className='full-height container'>
      {backToSearchBtn()}
      { messageType.length && statusMessage.length ? renderMessage() : null }
      <form>
        <div className='form-group'>
          <span>Order Number:</span>
          <input
            type='text'
            name='order_number'
            className='form-control'
            id='order_number'
            placeholder='Enter order number'
            value={activationKey}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setActivationKey(e.target.value)}
          />
        </div>
        <button
          disabled={!activationKey.replace(/\s+/g, '').length}
          className='btn btn-primary'
          type='submit'
          onClick={(e) => onSubmit(e)}
        >
          Register Organization
        </button>
      </form>
    </div>
  );
};

export default RegisterOrg;
