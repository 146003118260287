const auth = {
  isAuthenticated: false,
};

const checkAuthentication = () => {
  if (sessionStorage.getItem('token')) {
    auth.isAuthenticated = true;
  }
  return true;
};

const deauth = () => {
  sessionStorage.clear();
  auth.isAuthenticated = false;
  window.location.href = '/login';
};

export { auth, checkAuthentication, deauth };
