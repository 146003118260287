import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import AgreementsTable from '../../Components/AgreementsTable';
import Pagination from '../../Components/Pagination';

export const queryAgreements = async (fromDate: string, toDate: string) => {
  let res;
  const agreementAPI = '/api/v1/agreements/';
  const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } };
  try {
    const queryCall = `?created_at__range=${fromDate},${toDate}`;
    if (fromDate === '' || toDate === '') {
      res = await axios.get(`${agreementAPI}`, config);
    } else {
      res = await axios.get(`${agreementAPI}${queryCall}`, config);
    }
  } catch (err) {
    return err;
  }
  return res;
};

const AgreementsContainer = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [agreements, setAgreements] = useState([]);
  const [totalItems, setItems] = useState(0);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  React.useEffect(() => {
    async function fetchAllAgreements() {
      const res = await queryAgreements(fromDate, toDate);
      if (res.status === 200) {
        let nextVal, prevVal;
        setAgreements(res.data.results);
        if (res.data.results.length > 0) {
          setItems(res.data.count);
          if (res.data.next !== null) nextVal = res.data.next.split(':8000')[1]; // eslint-disable-line
          if (res.data.previous !== null) prevVal = res.data.previous.split(':8000')[1]; // eslint-disable-line
          setNextPage(nextVal || res.data.next);
          setPreviousPage(prevVal || res.data.previous);
          return res.data.results;
        }
        return res.data.results;
      }
      return res;
    }
    fetchAllAgreements();
    // eslint-disable-next-line
  }, []);

  const handlePagination = async (url: any) => {
    let res;
    const urlPage = url.split('=')[1];
    if (Number.isNaN(urlPage)) setCurrentPage(1);
    else setCurrentPage(Number(urlPage));
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` } };

    try {
      res = await axios.get(url, config);
      if (res.status === 200) {
        let nextVal, prevVal;
        setAgreements(res.data.results);
        if (res.data.results.length > 0) {
          setItems(res.data.count);
          if (res.data.next !== null) nextVal = res.data.next.split(':8000')[1]; // eslint-disable-line
          if (res.data.previous !== null) prevVal = res.data.previous.split(':8000')[1]; // eslint-disable-line
          setNextPage(nextVal || res.data.next);
          setPreviousPage(prevVal || res.data.previous);
          return res.data.results;
        }
        return res.data.results;
      }
    } catch (err) {
      return err;
    }
    return res;
  };

  const onToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToDate(e.target.value);
  };

  const onFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFromDate(e.target.value);
    if (moment().isBefore(e.target.value)) {
      setToDate(moment(e.target.value).add(7, 'd').format('YYYY-MM-DD'));
    } else { setToDate(moment().format('YYYY-MM-DD')); }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const res = await queryAgreements(fromDate, toDate);
    if (res.status === 200) {
      setAgreements(res.data.results);
      if (res.data.results.length > 0) {
        setItems(res.data.count);
        setCurrentPage(1);
        setNextPage(res.data.next);
        setPreviousPage(res.data.previous);
        return res.data.results;
      }
      return res.data.results;
    }
    return res;
  };

  const exportToCSV = async (e: any) => {
    e.preventDefault();
    const csvAPI = '/api/v1/agreement-csv/';
    let res;

    try {
      const queryCall = `?created_at__range=${fromDate},${toDate}`;
      if (fromDate === '' || toDate === '') {
        res = await axios({
          url: `${csvAPI}`,
          method: 'GET',
          headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
          responseType: 'blob',
        });
      } else {
        res = await axios({
          url: `${csvAPI}${queryCall}`,
          method: 'GET',
          headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
          responseType: 'blob',
        });
      }
    } catch (err) {
      return err;
    }
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'agreements.csv');
    document.body.appendChild(link);
    link.click();
    return res;
  };

  const modifyPage = () => {
    if (currentPage > 1) {
      return ((currentPage - 1) * 25 + 1);
    }
    return currentPage;
  };

  return (
    <div>
      <div className='search-container'>
        <div className='row mb-3'>
          <div className='col-8'>
            <h3>Agreements</h3>
          </div>
        </div>
        <form>
          <div className='row mb-2'>
            <div className='col-3'>
              <div className='input-group'>
                <div className='input-group-prepend' id='fromDate'>
                  <span className='input-group-text' id='fromDate'>From: </span>
                </div>
                <input type='date' className='form-control' value={fromDate} onChange={onFromDateChange} />
              </div>
            </div>
            <div className='col-3'>
              <div className='input-group'>
                <div className='input-group-prepend' id='toDate'>
                  <span className='input-group-text' id='toDate'>To: </span>
                </div>
                <input type='date' className='form-control' value={toDate} onChange={onToDateChange} />
              </div>
            </div>
            <div className='col-6'>
              <button className='btn btn-primary mr-1' type='submit' onClick={(e) => onSubmit(e)}>Search</button>
              <button className='btn btn-secondary mr-1' type='submit' onClick={(e) => exportToCSV(e)}>Export to CSV</button>
            </div>
          </div>
        </form>
        <AgreementsTable agreements={agreements} pageModifier={modifyPage()} />
        <Pagination totalItems={totalItems} next={nextPage} previous={previousPage} handlePagination={handlePagination} currentPage={currentPage} />
      </div>
    </div>
  );
};

export default AgreementsContainer;
