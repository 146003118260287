import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import axios from 'axios';
import { useDispatch } from 'react-redux';

const AddOrder = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [expiration, setExpiration] = useState(moment().add(13, 'M'));
  const [onFocus, setOnFocus] = useState(false);
  const id = window.location.href.split('/')[4];

  const getSingleOrder = async () => {
    const orderAPI = '/api/v1/orders';
    const getConfig = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      };
      return config;
    };
    let res;
    try {
      const config = await getConfig();
      res = await axios.get(`${orderAPI}/detail/${id}`, config);
    } catch (e) {
      return false;
    }
    if (res.status === 200) {
      setName(res.data.customer_name);
      if (moment(res.data.expiration).isValid()) setExpiration(moment(res.data.expiration));
      else setExpiration(moment().add(13, 'M'));
      setOrderNumber(res.data.order_number);
    }
    return true;
  };

  useEffect(() => {
    if (id) {
      getSingleOrder();
    }
  }, []);

  const createOrder = async (e: any) => {
    e.preventDefault();
    let res;
    const getConfig = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      };
      return config;
    };
    try {
      const config = await getConfig();
      const data = {
        customer_name: name,
        expiration,
        order_number: orderNumber,
      };
      if (id) res = await axios.patch(`/api/v1/orders/detail/${id}`, data, config);
      else res = await axios.post('/api/v1/orders/', data, config);
    } catch (err) {
      dispatch({ type: 'ADD_ORDER', payload: { msg: 'Failed to create order', msg_type: 'danger' } });
      window.location.assign('/orders');
      return false;
    }
    dispatch({ type: 'ADD_ORDER', payload: { msg: 'New order created', msg_type: 'success' } });
    window.location.assign('/orders');
    return res;
  };

  return (
    <div className='full-height container'>
      <div className='row'>
        <div className='col'>
          <h1>
            { id ? 'Edit' : 'Add' }
            {' '}
            Order
          </h1>
        </div>
        <div className='col d-flex justify-content-end'>
          <button className='btn btn-primary' type='button' onClick={() => history.push('/orders')}>Back to Orders Search</button>
        </div>
      </div>
      <form>
        <div className='form-group'>
          <span>Customer Name: </span>
          <input
            type='text'
            className='form-control'
            placeholder='Customer Name...'
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <span>Order Number: </span>
          <input
            type='text'
            className='form-control'
            placeholder='Order Number...'
            onChange={(e) => setOrderNumber(e.target.value)}
            value={orderNumber}
          />
          <span>Expiration Date: </span>
          <br />
          <div>
            <SingleDatePicker
              date={expiration} 
              onDateChange={(date) => date && setExpiration(date)}
              focused={onFocus}
              onFocusChange={({ focused }) => setOnFocus(focused)}
              id='your_unique_id'
            />
          </div>
        </div>
        <button className='btn btn-primary' type='submit' onClick={(e) => createOrder(e)}>Save</button>
      </form>
    </div>
  );
};

export default AddOrder;
