import React, { useState } from 'react';
import axios from 'axios';
import { auth } from '../../lib/auth';

export const login = async (username: string, password: string) => {
  const payload = {
    username,
    password,
  };
  let res;
  try {
    res = await axios.post('/api/v1/login/', payload);
  } catch (e) {
    const msg = { status: e.response.status, data: e.response.data.errors.error[0] };
    return msg;
  }
  if (res.status === 200) {
    sessionStorage.setItem('token', res.data.token);
    auth.isAuthenticated = true;
  }
  return res;
};

export const submitLogin = async (
  e: any, userUsername: string, userPass: string, setError: (msg: string) => void,
) => {
  e.preventDefault();
  setError('');

  if (!userUsername.length) return setError('Username is required');
  if (!userPass.length) return setError('Password is required');

  const res = await login(userUsername, userPass);
  if (res.status !== 200) {
    setError(res.data);
    return false;
  }
  const tokenExists = sessionStorage.getItem('token');
  /* istanbul ignore else */
  if (tokenExists !== undefined) {
    auth.isAuthenticated = true;
    window.location.assign('/organizations');
  }
  return true;
};

const LoginContainer = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [usernameInput, setUsernameInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');

  const renderError = () => {
    if (!errorMessage.length) return null;
    return <div className='alert alert-danger text-center'>{errorMessage}</div>;
  };

  return (
    <div className='full-height login-wrapper'>
      <div className='container'>
        <div className='login-form'>
          <h2 className='text-center'>Welcome</h2>
          <p className='text-center lead'>Please sign in to get started</p>
          {renderError()}
          <form>
            <div className='form-group'>
              <span id='username-label'>Username</span>
              <input
                type='text'
                className='form-control'
                id='username'
                placeholder='Username'
                onChange={(e: any) => setUsernameInput(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <span id='password-label'>Password</span>
              <input
                type='password'
                className='form-control'
                id='password'
                placeholder='Password'
                onChange={(e: any) => setPasswordInput(e.target.value)}
              />
            </div>
            <div className='full-width'>
              <button type='button' className='btn login-btn' onClick={(e) => submitLogin(e, usernameInput, passwordInput, setErrorMessage)}>
                Log in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginContainer;
