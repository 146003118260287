import React from 'react';

const DeleteModal = ({ item, refetch, onDelete }: {
  item: any,
  refetch: any,
  onDelete: (e: any, id: string, getMore: Function) => boolean | Promise<boolean>
}) => {
  const target = `deleteModal${item.id}`;
  return (
    <div className='modal fade' id={target} role='dialog' aria-labelledby='exampleModalLabel' aria-hidden='true'>
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='exampleModalLabel'>Delete</h5>
            <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            Are you sure you want to delete  
            {' '}
            {item.name}
            ? 
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-secondary' data-dismiss='modal'>Cancel</button>
            <button
              type='button'
              className='btn btn-primary'
              onClick={(e) => { onDelete(e, item.id, refetch); }}
              data-dismiss='modal'
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
