import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Button from './Button';
import DeleteModal from './DeleteModal';
 
type OrganizationProperties = {
  id: number;
  name: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  telephone: string;
  order_number: string;
};

const onEdit = async (id:number) => {
  const OrganizationAPI = '/api/v1/schools/';
  const getConfig = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    return config;
  };
  let res;
  try {
    const config = await getConfig();
    res = await axios.get(`${OrganizationAPI}${id}`, config);
  } catch (e) {
    return e;
  }
  window.location.href = `/edit-organization/${id}`;
  return res;
};

export const onDeleteSite = async (e: any, id: string, fetchMoreSites: Function) => {
  e.persist();
  const OrganizationAPI = '/api/v1/schools/';
  let res;
  const getConfig = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    };
    return config;
  };
    
  try {
    const config = await getConfig();
    res = await axios.delete(`${OrganizationAPI}${id}`, config);
    sessionStorage.setItem('message', 'Organization deleted successfully');
    sessionStorage.setItem('messageType', 'Success');
  } catch (error) {
    sessionStorage.setItem('message', 'Organization was not deleted');
    sessionStorage.setItem('messageType', 'Fail');
    return false;
  }
  sessionStorage.setItem('message', 'Organization deleted successfully');
  sessionStorage.setItem('messageType', 'Success');
  fetchMoreSites(e);
  return true;
};

export const organizationData = (organizations: any, callback: Function, pageModifier: number) => {
  if (organizations === null || organizations === undefined) return null;
  const mapped = organizations.map((organization: OrganizationProperties, index: number) => {
    const target = `#deleteModal${organization.id}`;
    return (
      <tr key={organization.id}>
        <th scope='row'>{index + pageModifier}</th>
        <td>{organization.name}</td>
        <td>{organization.address}</td>
        <td>{organization.city}</td>
        <td>{organization.state}</td>
        <td>{organization.zipcode}</td>
        <td>{organization.telephone}</td>
        <td>
          <div className='row'>
            <div className='col-1 d-flex'>
              <Button name={<FontAwesomeIcon icon={faEdit} />} className='border-0 bg-white' onClick={() => onEdit(organization.id)} />
            </div>
            <div className='col-1 d-flex'>
              {/* Button trigger modal */}
              <Button name={<FontAwesomeIcon icon={faTrashAlt} />} className='border-0 bg-white' dataToggle='modal' dataTarget={target} />
              {/* Modal */}
              <DeleteModal item={organization} refetch={callback} onDelete={onDeleteSite} />
            </div>
            <div className='col d-flex'>
              <Button
                name={organization.order_number ? 'Activated' : 'Activate'}
                className='activateBtn mr-2'
                onClick={() => { window.location.href = `/activate/${organization.id}`; return true; }}
              />
              <Button
                name='Link Order'
                className='activateBtn'
                onClick={() => { window.location.href = `/link/${organization.id}`; return true; }}
              />
            </div>
          </div>
        </td>
      </tr>
    ); 
  });

  if (organizations.length === 0) return <h6>No organizations match that search</h6>;
  return (
    <div className='table-responsive'>
      <table className='table'>
        <thead className='thead-dark'>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Organization Name</th>
            <th scope='col'>Address</th>
            <th scope='col'>City</th>
            <th scope='col'>State</th>
            <th scope='col'>ZipCode</th>
            <th scope='col'>Telephone</th>
            <th scope='col'>Modify</th>
          </tr>
        </thead>
        <tbody>
          {mapped}
        </tbody>
      </table>
    </div>
  );
};

const OrgTable = ({ organizations, callback, pageModifier }: any) => organizationData(organizations, callback, pageModifier);

export default OrgTable;
