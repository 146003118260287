import React from 'react';

type AgreementProperties = {
  activation_key: string;
  first_name: string;
  last_name: string;
  title: string;
  work_phone: string;
  mobile_phone: string;
  email: string;
}

export const mapAgreementData = (agreements: any) => {
  if (agreements === null || agreements === undefined) return null;
  const mappedAgreements = agreements.map((agreement: AgreementProperties) => (
    <tr key={agreement.activation_key}>
      <td>{agreement.activation_key}</td>
      <td>{agreement.first_name}</td>
      <td>{agreement.last_name}</td>
      <td>{agreement.title}</td>
      <td>{agreement.work_phone}</td>
      <td>{agreement.mobile_phone === null ? 'none' : agreement.mobile_phone}</td>
      <td>{agreement.email}</td>
    </tr>
  ));
  
  if (agreements.length === 0) return <h6>No agreements were found for that search.</h6>;
  return (
    <div className='table-responsive'>
      <table className='table'>
        <thead className='thead-dark'>
          <tr>
            <th scope='col'>Activation Key</th>
            <th scope='col'>First Name</th>
            <th scope='col'>Last Name</th>
            <th scope='col'>Title</th>
            <th scope='col'>Work Phone</th>
            <th scope='col'>Mobile Phone</th>
            <th scope='col'>Email</th>
          </tr>
        </thead>
        <tbody>
          {mappedAgreements}
        </tbody>
      </table>
    </div>
  );
};

const AgreementsTable = ({ agreements }: any) => mapAgreementData(agreements);

export default AgreementsTable;
