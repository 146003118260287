/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  BrowserRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import LoginContainer from './Containers/Login/Login';
import NotFound from './Components/notFound';
import SearchContainer from './Containers/SearchScreen';
import AgreementsContainer from './Containers/Agreements/Agreements';
import { auth, checkAuthentication } from './lib/auth';
import AddEditOrganization from './Containers/Organizations/AddEditOrganization';
import ActivateOrg from './Containers/Organizations/ActivateOrganization';
import OrderSearch from './Containers/Orders/OrderSearch';
import AddOrder from './Containers/Orders/AddOrder';
import NavBar from './Components/Navigation';
import LinkOrder from './Containers/Organizations/LinkOrder';

checkAuthentication();

export const PrivateRoute = ({ component: Component, operation }: any) => (
  <Route render={(props: any) => (
    auth.isAuthenticated ? (
      <>
        <NavBar />
        <Component {...props} operation={operation} />
      </>
    ) : (
      <Redirect to='/login' />
    )
  )}
  />
);

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path='/' component={LoginContainer} />
      <Route exact path='/login' component={LoginContainer} />      
      <PrivateRoute exact path='/organizations' component={SearchContainer} />
      <PrivateRoute exact path='/add-organization' component={AddEditOrganization} operation='Add' />
      <PrivateRoute exact path='/edit-organization/:id' component={AddEditOrganization} operation='Edit' />
      <PrivateRoute exact path='/activate/:id' component={ActivateOrg} />
      <PrivateRoute exact path='/link/:id' component={LinkOrder} />
      <PrivateRoute exact path='/orders' component={OrderSearch} />
      <PrivateRoute exact path='/add-order' component={AddOrder} />
      <PrivateRoute exact path='/edit-order/:id' component={AddOrder} />
      <PrivateRoute exact path='/agreements' component={AgreementsContainer} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default App;
