import React from 'react';

type ButtonProps = {
  onClick?: any;
  name: any;
  className?: string;
  dataToggle?: string;
  dataTarget?: string;
};

const Button: React.FC<ButtonProps> = ({
  onClick, name, className, dataToggle, dataTarget,
}) => (
  <button
    type='button'
    className={className}
    onClick={onClick}
    data-toggle={dataToggle}
    data-target={dataTarget}
  >
    {name}
  </button>
);

export default Button;
